import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { FaExclamationCircle } from 'react-icons/fa'

export const Container = styled.div``
export const SvgFaExclamationCircle = styled(FaExclamationCircle)``
export const RowStart = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Column = styled.div`
  width: 100%;
`
export const BorderedColumn = styled.div`
  border: 1px solid rgba(109, 94, 94, 0.58039);
  border-radius: 1rem;
  background: white;
  padding: 2rem;
  width: 100%;
`

export const HorizontalIconWrapper = styled.div`
  width: auto;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  border-radius: 1rem;
  padding: 0.4rem;
  transition: all 0.3s ease;
  h1 {
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
    background: var(--redGR);
    color: white;
    transition: all 0.3s ease;
    transform: translate3d(0px, -4px, 0px);
  }
  svg:hover {
    border-color: white;
  }
`
export const HorizontalTextWrapper = styled.div`
display:flex;
flex-wrap: wrap;
text-align: left;
justify-content: unsafe;
margin-left: 1rem;
`

export const IconCard = styled.div`
width: 4rem;
height: 4rem;
border: 1px solid #6d5e5e94;
border-radius: 16px;
transition: all .1s ease-in;  
`
export const IconCardColumns = styled.div`
  width: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  border: 1px solid #6d5e5e94;
  border-radius: 16px;
  transition: all 0.1s ease-in;
`
export const CardWrapper = styled.div``
export const Cards = styled.div``
export const Header = styled.h1`
font-size:  var(--h1);
font-weight: var(--bold)
`
export const SubHeader = styled.h2`
  font-size: ${props => props.Size || '18px'};
  color: ${props => props.Color || 'black'};
`
export const Text = styled.p`
  margin-top: ${props => (props.high ? '3rem' : '0rem')};
  font-size: 16px;
  font-weight:100;
  color: ${props => props.color || 'black'};
`

export const DividerForRow = styled.div`
  margin-top: ${props => props.Top || '5em'};
  margin-bottom: ${props => props.Bottom || '5em'};
`

export const RetmonCTAButton = styled.button`
  background: ${props => props.Color || 'black'};
  color: white;
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  float: ${props => (props.right ? 'right' : 'unset')};
  width: ${props => props.width || '-webkit-fill-available;'};
  transition: all 0.3s ease;
  &:hover {
    background: #000000c9;
    transition: all 0.3s ease;
  }
  &:active,
  &:focus {
    background: #000000c9;
  }
`
export const Span = styled.span`
  color: ${props => props.Color || 'var(--red)'};
`
export const ModuleImage = styled.img`
  width: 15rem;
  margin-bottom: 5px;
`
export const SolutionLogo = styled(Img)`
  width: 15rem;
  margin-bottom: 5px;
`
export const SolutionSVGLogo = styled.img`
  width: 15rem;
  margin-bottom: 5px;
`
export const TableImage = styled.img`
  margin-bottom: 5px;
  width: ${props => props.w || '1rem'};
  margin: auto;
`
export const ModuleColored = styled.div`
  width: 100%;
  padding: 0.7rem;
  margin-top: 2rem;
  border-style: solid;
  border-color: ${props => props.Color || 'var(--red)'};
  transition: all 0.2s ease-in;
  &:hover {
    background: #0000000a;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }
`
export const CompareTable = styled.table`
  width: 100%;
`
export const CompareTableTH = styled.th`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
export const CompareTableTD = styled.td`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size:14px;
  ${SvgFaExclamationCircle} {
    font-size: 12px;
    color: gray;
    transition: color 0.2s ease-in;
  }
  ${SvgFaExclamationCircle}:hover {
    cursor: pointer;
    transition: color 0.2s ease-in;
    color: black;
  }
`

export const CompareTableTRGray = styled.tr`
  background: #ccc3;
  font-weight: 700;
`
export const CompareTableTRTitle = styled.tr`
  font-weight: 700;
`
export const CompareTableTR = styled.tr`
  font-weight: 700;
`

export const CompareTableTRSub = styled.tr`
  font-weight: normal;
`
export const FlexCardImages = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  img {
    width: 16rem;
  }
  ${media.lessThan('medium')`
display: grid;
justify-content: center;
`}
`
export const FeedbackWrapper = styled.div`
  ${media.lessThan('medium')`
display: none;
`}
`

export const CardImages = styled.img`
  &:hover {
    cursor: pointer;
    transform: scale3d(1.04, 1.04, 1.04);
    transition: transform 0.2s ease-in;
  }
  ${media.lessThan('medium')`
margin-bottom: 2rem;
width: 21rem!important;
`}
`
export const SubItem = styled.div`
  position: absolute;
  background: white;
  backdrop-filter: blur(16px);
  border: 1px solid;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  opacity: 0;
  display: grid;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
  a {
    font-size: 13px;
  }
  ${SvgFaExclamationCircle}:hover & {
    visibility: visible;
    opacity: 1;
    display: grid;
  }
`
export const Title = styled.h1`
font-weight: 400;
font-style: normal;
font-size: 32px;
`;
export const Title2 = styled.h2`
font-weight: 400;
font-style: normal;
font-size:  var(--h2);
font-weight: var(--bold);
`;

export const SubTitle = styled.h3`
margin-top: 0.4rem;
margin-bottom: 0.7rem;
font-size: var(--textBase);
color: var(--info);
`;
