import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as S from '../components/Solutions/styled'
import * as P from '../components/LandingPage/styled'
import DocumentDownload from "../components/LandingPage/documentDownload"

const SolutionList = ({ data, pageContext: { locale } }) => {
  const { retmonTitle, retmonDescription, lang, navbar, solutionsPage, landingPage } = useTranslations()
  const solutionListTR = data.TR.edges
  const solutionListEN = data.EN.edges

  return (
    <div className="homepage">

      <SEO
        title={`${navbar.solutions} | ${retmonTitle}`}
        description={retmonDescription}
        lang={lang} />
      <S.Container>
        <S.RowStart>
          <S.Title>{solutionsPage.title}</S.Title>
          <div className='solution-grid'>
            {locale === 'en'
              ? solutionListEN.map(
                ({
                  node: {
                    excerpt,
                    frontmatter: { subTitle, color, title, desc },
                    fields: { slug },
                  },
                }) => (
                  <S.Column>
                    <br />
                    <LocalizedLink to={`/${slug}`}>
                      <div>
                        <S.Title2>{title}</S.Title2>
                        <S.SubTitle>{subTitle}</S.SubTitle>
                        <S.Text>{desc}</S.Text>
                        <br />
                        <P.ButtonOutline>
                          {landingPage.more}
                        </P.ButtonOutline>
                      </div>
                    </LocalizedLink>
                  </S.Column>
                )
              )
              : solutionListTR.map(
                ({
                  node: {
                    frontmatter: { subTitle, title, desc, color },
                    fields: { slug },
                  },
                }) => (
                  <S.Column>
                    <br />
                    <LocalizedLink to={`/${slug}`}>
                      <S.ModuleColored Color={color}>
                        <S.Title2>{title}</S.Title2>
                        <S.SubTitle>{subTitle}</S.SubTitle>
                        <S.Text>{desc}</S.Text>
                        <br />
                        <P.ButtonOutline >
                          İncele
                      </P.ButtonOutline>
                      </S.ModuleColored>
                    </LocalizedLink>
                  </S.Column>
                )
              )}
          </div>
        </S.RowStart>
        <S.DividerForRow />
      </S.Container>
    </div>
  )
}

export const query = graphql`
  query SolutionList {
    EN: allMarkdownRemark(filter: {frontmatter: {page: {eq: "solution"}}, fields: {locale: {eq: "en"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 300)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            desc
            subTitle
            title
            color
          }
        }
      }
    }
    TR: allMarkdownRemark(filter: {frontmatter: {page: {eq: "solution"}}, fields: {locale: {eq: "tr"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 300)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            desc
            subTitle
            title
            color
          }
        }
      }
    }
  }
`

export default SolutionList
